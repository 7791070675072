/** @format */

import React from 'react';

import Layout from '../components/layout';
import SmallPhoto from '../components/SmallPhoto';
import SmallerPhoto from '../components/SmallerPhoto';
import Photo from '../components/Photo';
// import Text from "../components/Text"
import Title from '../components/Title';
import ProjectBar from '../components/ProjectBar';
// import Test from '../components/test.md';

import smallPhoto3 from '../images/luonboard.png';

import smallPhoto8 from '../images/lucommunity.png';

import smallPhoto9 from '../images/lumessages.png';
import luia from '../images/ludiscover.png';
import luskip from '../images/luskip.png';
import luProfiles from '../images/luprofiles.png';
import luheader from '../images/lucover.png';
import otherwork from '../images/otherwork.jpg';
import luoldandnew from '../images/luoldandnew.png';

import { navigate } from 'gatsby-link';

import lu from '../images/lucover2.png';
import mb from '../images/mbcoverlarge.png';
import penskecover from '../images/penskecoverlarge.png';
import ym from '../images/ymcoverlarge.png';

import dhl from '../images/dhl1.png';

const LU = () => {
    return (
        <Layout>
            {/* <ProjectBar /> */}
            <Title
                title="Live Unlimited"
                subtitle="Creating Community and Promoting Engagement"
                categories="UI/UX Design, Development"
            />
            <Photo link={luheader} />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2>Overview</h2>
                        <h3 className="first">Roles</h3>
                        <p className="firstp">
                            UI/UX Design, Front-End Development, Project Lead
                        </p>
                        <h3>Client</h3>
                        <p className="firstp">
                            Live Unlimited - A Vancouver based young
                            professional community
                        </p>
                        <h3>Problem</h3>
                        <p className="firstp">
                            Low community engagement between events and low
                            event attendance.
                        </p>
                        <h3>Business Goal</h3>
                        <p className="firstp">
                            Increase community engagement and event attendance.
                        </p>
                        <h3>Solution</h3>
                        <p className="firstp">
                            A platform to act as a central hub for the members
                            of the Live Unlimited community. Allowing users to
                            create a profile, make posts to a community feed,
                            learn about and RSVP to events, and find and chat
                            with other community members.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                    </div>
                </div>
            </div>
            <Photo link={smallPhoto3} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3 className="first">Background</h3>
                        <p className="firstp">
                            Live Unlimited was a Vancouver based community for
                            students and young professionals to discover new
                            career opportunities while empowering their ability
                            to give through Charitable Impact. As users attended
                            events they earned points which in turn let them
                            donate cash to different local charitable
                            organizations from a fund Live Unlimited put
                            together. Live Unlimited had around 100 members at
                            the time of this project.
                        </p>
                        <p>
                            I did not design the charitable donations portion of
                            the application so that will not be covered here.
                        </p>

                        <h3>Problem</h3>
                        <p className="firstp">
                            Members were informed about events through email and
                            would often only communicate at events. The
                            community had very little engagement between events
                            and very few members took advantage of the
                            charitable donations portion of the organization.
                        </p>
                        <h3>Solution</h3>
                        <p className="firstp">
                            A mobile application that allows users to create a
                            profile, make posts to a community feed, learn about
                            and RSVP to events, and find and chat with other
                            users in the community. The goal was to build
                            something that felt like a social media site.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={luia} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3 className="first">
                            Making it easy to find new events.
                        </h3>
                        <p className="firstp">
                            Previously events were sent out in a monthly email.
                            The client wanted a better solution than this so I
                            included a discover tab to show upcoming events and
                            allow users to RSVP to them. In addition event hosts
                            would be able to update events, monitor RSVPs, and
                            communicate with attendees.
                        </p>
                        <p>
                            I performed user research with Live Unlimited
                            community members to determine what information
                            would be best to show on the event cards and what
                            kind of notifications would be best to send to
                            remind and motivate users to attend events.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={smallPhoto8} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3 className="first">
                            Community page to inspire conversation.
                        </h3>
                        <p className="firstp">
                            Live Unlimited was designed as a young professional
                            networking community, but the group had virtually no
                            conversation between events. I designed a social
                            media style feed to give users a familiar
                            experience.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto
                link={luProfiles}
                subtitle="As users gain more Impact points by going to events, their level within the community increases."
            />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3 className="first">Personalized profile pages. </h3>
                        <p className="firstp">
                            Live Unlimited aimed to help users connect by
                            highlighting shared Missions, Goals, and Passions.
                            During onboarding, users answered questions to
                            reflect these values, and I was tasked with
                            designing a profile page to showcase their
                            responses.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={luoldandnew} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <p>
                            The client provided an initial concept for the
                            profile, but it hid information behind buttons. I
                            argued that this contradicted the goal of fostering
                            connections. Instead, I proposed a design that
                            displayed these key details directly on the profile,
                            ensuring users could easily connect based on shared
                            values.
                        </p>
                        <p>
                            Users could also see posts and any mutual events
                            attended, all with the goal to inspire conversation
                            and connection.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={luskip} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3 className="first">
                            Removing friction during onboarding.
                        </h3>
                        <p className="firstp">
                            During testing, users noted that being required to
                            answer challenging questions upfront made them less
                            likely to complete onboarding. To fix this, I added
                            a skip button, allowing users to bypass these
                            questions and revisit them later through their
                            profile page. This change reduced friction and
                            improved the likelihood of users completing the
                            onboarding process.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={smallPhoto9} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3 className="first">Impact</h3>
                        <p className="firstp">
                            Post release of the app we saw an immediate increase
                            in event attendance and community engagement.
                        </p>
                        <p>
                            Event attendance saw a{' '}
                            <b>
                                significant 50% increase, rising from an average
                                of 35 to 54 attendees per event.
                            </b>{' '}
                            This improvement is largely attributed to the push
                            notification reminders, which proved more effective
                            and convenient for users compared to emails.
                        </p>
                        <p>
                            Before the app's release, community engagement was
                            nearly non-existent. However,
                            <b>
                                {' '}
                                within the first two weeks, the community feed
                                saw approximately 40 posts, with similar surges
                                around events.
                            </b>{' '}
                            However, activity tended to decrease during the
                            periods between events.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2></h2>
                        <p></p>
                    </div>
                </div>
            </div>
            {/* <ProjectBar /> */}
            <div className="section" id="content-desktop">
                <div className="container">
                    <div className="project-bar-container">
                        <div
                            className="project-container"
                            onClick={() => navigate(`/yardmanager/`)}>
                            <img className="project-photo" src={ym}></img>
                            <div className="project-name">
                                Penske: Yard Manager
                            </div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/penske/`)}>
                            <img
                                className="project-photo"
                                src={penskecover}></img>
                            <div className="project-name">
                                Penske: Subscriptions
                            </div>
                        </div>
                        <div
                            className="project-container-active"
                            onClick={() => navigate(`/liveunlimited`)}>
                            <img className="project-photo" src={lu}></img>
                            <div className="project-name">Live Unlimited</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/otherwork/`)}>
                            <img
                                className="project-photo"
                                src={otherwork}></img>
                            <div className="project-name">Other Work</div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default LU;

// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <h3>Usability Testing</h3>
//         <p>
//             For this portion, I was not the lead, but I helped
//             with creating the questions for users, conducting
//             the testing, and incorporating the feedback.
//         </p>
//         <p>
//             <b>
//                 We used the Think-Aloud Approach with specific
//                 tasks to fulfill.
//             </b>
//         </p>
//         <p>
//             <b>Goals:</b>
//         </p>
//         <ul>
//             <li>Test the navigability of the app</li>
//             <li>
//                 Identify any pain points and confusion in the
//                 flow
//             </li>
//             <li>
//                 Get non-stakeholder feedback on the design of
//                 the app
//             </li>
//         </ul>

//         <h4>Feedback</h4>

//         <p>
//             We received feedback in two main areas of the
//             application: onboarding and the wallet.
//         </p>
//         <h4>Onboarding Feedback</h4>
//         <p>
//             The feedback we recieved indicated that the users
//             felt overwhelmed at some of the questions. They
//             indicated that they would like to answer them, but
//             at their own pace and being forced to answer them
//             before even entering the app could deter them from
//             using it at all. So we added a skip button.
//         </p>
//         <h4>Wallet Feedback</h4>
//         <p>
//             Initially when a user donated points we indicated it
//             by using a downward pointing red arrow in their
//             transaction record, our testers pointed out that
//             this made them feel that the donation was a negative
//             action. In response, we changed the graphic to a
//             gold star to remove the negative connotation.
//         </p>
//     </div>
// </div>
// </div>

// <Title
// title="Live Unlimited"
// subtitle="Creating Community and Promoting Charity"
// categories="UI/UX Design, Development"
// />
// <Photo link={luheader} />
// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <h2>Overview</h2>
//         <h3 className="first">Roles</h3>
//         <p className="firstp">
//             UI/UX Design, Front-End Development, Project Lead
//         </p>
//         <h3>Client</h3>
//         <p className="firstp">
//             Live Unlimited - A Vancouver based young
//             professional community
//         </p>
//         <h3>Problem</h3>
//         <p className="firstp">
//             Low community engagement between events and low
//             event attendance.
//         </p>
//         <h3>Business Goal</h3>
//         <p className="firstp">
//             Build a platform to act as a central hub for the
//             members of the Live Unlimited community.
//         </p>
//         <h3>Solution</h3>
//         <p className="firstp">
//             A platform to act as a central hub for the members
//             of the Live Unlimited community. Allowing users to
//             create a profile, make posts to a community feed,
//             learn about and RSVP to events, and find and chat
//             with other community members.
//         </p>
//     </div>
// </div>
// </div>
// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <hr></hr>
//         <h2>Approach</h2>
//         <h3 className="first">Gathering Requirements</h3>
//         <p className="firstp">
//             We began by working closely with the founders to
//             understand their goals for the application. From the
//             get go they had a very good idea of the main
//             features they wanted in their app. Our job was to
//             help fill in the gaps.
//         </p>
//         <p>
//             Using the information we gathered and working
//             alongside the developers we created a document
//             listing out their requested features and the
//             features we added to support their ideas.
//         </p>
//         <h3>Information Architecture</h3>
//         <p className="firstp">
//             To better visualize the application I took the
//             features document and turned it into an information
//             architecture diagram.
//         </p>
//     </div>
// </div>
// </div>
// <SmallPhoto link={luia} subtitle="" />
// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <hr></hr>
//         <h2>Design Process</h2>
//         <h3 className="first">Design Goals</h3>
//         <p className="firstp">
//             The goal was to design an interface that fostered
//             communication and connection between members of the
//             Live Unlimited community.
//         </p>
//         <h3>Iterations</h3>
//         <p className="firstp">
//             The client already had a few sample designs for some
//             sections of the application. A handful of the
//             onboarding screens looked ready to go into
//             prototyping, but for most screens we started with
//             sketches because we saw gaps in their designs.
//         </p>
//         <p>
//             For instance, on the events page, each event was
//             represented by only an image and the event's name.
//             We sketched it out to include more information such
//             as dates, times, and number of attendees so that the
//             user could see more information instantly.
//         </p>
//     </div>
// </div>
// </div>
// <SmallerPhoto
// link={smallPhoto1}
// subtitle="Original Onboarding and Profile Concept Mock-Ups From Client"
// link2={smallPhoto12}
// subtitle2="Early Sketches of Events, Messages, and Wallet Pages"
// />
// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <p>
//             Another one of their designs that needed work was
//             the profile page.
//             <strong>
//                 The app's goal was to create connections, but
//                 users' info was hidden behind buttons and not
//                 easily visible.
//             </strong>
//         </p>
//     </div>
// </div>
// </div>
// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <h3>Increasing Profile Info Visibility</h3>
//         <p className="firstp">
//             They really liked the idea of having the text
//             hidden. So we first tried to compromise by placing
//             the text behind an accordion and leaving the first
//             answer expanded. That way users could instantly read
//             one of the profile’s answers and if interested they
//             could continue to look at the others.
//         </p>
//     </div>
// </div>
// </div>
// <SmallPhoto link={smallPhoto16} subtitle="" />
// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <p className="">
//             Presenting the example with the accordion opened the
//             client up to the idea of not having the text hidden.
//             So we took a step further and removed the accordion,
//             and just displayed text.
//         </p>
//     </div>
// </div>
// </div>
// <SmallPhoto link={smallPhoto14} subtitle="" />
// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <h3>Promoting Individuality</h3>
//         <p className="firstp">
//             We then experimented with a couple different header
//             options.
//         </p>
//         <p>
//             We tested options with potential users and found
//             users preferred the ability to choose their own
//             header photo, especially since the background color
//             of their profile was assigned by their level and
//             could not be customized.
//         </p>
//     </div>
// </div>
// </div>
// <Photo link={smallPhoto15} subtitle="" />
// {/* <Photo link={iterations} /> */}
// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <p>
//             After aligning on our design that achieved our goals
//             of displaying a user's information prominently and
//             allowing them to express individuality we added the
//             user’s passions at the top of their profile as a
//             quick way to see if this was someone you may want to
//             connect with.
//         </p>
//     </div>
// </div>
// </div>
// <Photo link={smallPhoto11} subtitle=" " />

// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <hr></hr>
//         <h2>User Research</h2>
//         <p className="firstp">
//             We led usability testing sessions where we had
//             participants use the Think-Aloud approach while
//             fulfilling specific tasks.
//         </p>
//         <p>
//             Through testing we found that:
//             <ul>
//                 <li>
//                     Users would likely drop off during
//                     onboarding because of the hard questions
//                     that needed to be answered
//                 </li>
//                 <li>
//                     Users indicated that they wanted to answer
//                     the questions, but felt doing it all during
//                     sign up was very burdensome
//                 </li>
//             </ul>
//         </p>
//         <p>
//             As a result of our findings, we:
//             <ul>
//                 <li>
//                     Added a skip option to the onboarding
//                     process to increase user retention
//                 </li>
//                 <li>
//                     Ensured users could easily add/edit their
//                     question answers in their profile page
//                 </li>
//             </ul>
//         </p>
//     </div>
// </div>
// </div>
// <Photo link={smallPhoto6} subtitle="Sign Up Questions" />
// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <hr></hr>
//         <h2>Development</h2>
//         <h3 className="first">Component Library</h3>
//         <p className="firstp">
//             To assist in development first I broke the designs
//             apart into reusable components within XD.
//         </p>
//         <p>
//             Then while the other developers set up the back-end
//             I coded the components in React Native and started
//             wiring up the front-end.
//         </p>
//     </div>
// </div>
// </div>
// <SmallPhoto
// link={smallPhoto13}
// subtitle="Part of the Component Library"
// />
// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <hr></hr>
//         <h2>Design</h2>
//         <h3 className="first">Final Designs</h3>
//         <p className="firstp">
//             Below are a selection of screens from the final
//             application.
//         </p>
//     </div>
// </div>
// </div>
// <Photo link={smallPhoto3} subtitle="Sign Up Process Intro" />
// <br></br>
// <br></br>
// <Photo link={smallPhoto10} subtitle="Community Section" />
// <br></br>
// <br></br>
// <Photo
// link={smallPhoto7}
// subtitle="Event Section & Events Details Page"
// />
// <br></br>
// <br></br>
// <Photo link={smallPhoto9} subtitle="Messages Section" />
// {/* <br></br>
// <br></br>
// <SmallPhoto link={smallPhoto8} subtitle="Wallet Section" /> */}
// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <hr></hr>
//         <h2>Reflection</h2>
//         <h3>Key Learnings</h3>
//         <p>
//             <ul className="firstp">
//                 <li>
//                     When working with international clients, be
//                     aware that accessibility laws can change and
//                     design accordingly. In this case, Canadian
//                     accessibility laws are much stricter than
//                     the USA's.
//                 </li>
//                 <li>
//                     Conduct user testing earlier. Test designs
//                     throughout the process with users, not just
//                     near and at the end.
//                 </li>
//             </ul>
//         </p>
//     </div>
// </div>
// </div>
